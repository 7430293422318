
import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/ui/Hero/Hero";
import Section from "../components/ui/Section/Section";

const Impressum = ({ data }) => {
   
    return (
        <Layout crumbs={[{ label: "Home", url: "" }, { label: "Impressum", url: "impressum" }]}>
            <Hero 
                title="Impressum" 
            />
            <Section>
                <div className="content">
                    <p>
                        <strong>HIS Print &amp; Service GmbH &amp; Co. KG</strong><br />
                        Zur Sch&ouml;nhalde 1<br />
                        89352 Ellzee
                    </p>
                    <p>
                        Telefon: +49 (0)8283/99810-0<br />
                        Telefax: +49 (0)8283/ 99810-12<br />
                        E-Mail: <a href="mailto:info@his-print-service.de">info@his-print-service.de</a><br />
                        Internet: www.his-print-service.de
                    </p>
                    <p>
                        Pers&ouml;nlich haftende Gesellschafterin der HIS Print &amp; Service GmbH &amp; Co. KG; HIS Print &amp; Service-Verwaltungs GmbH<br />
                        Registergericht: HIS Print &amp; Service-Verwaltungs GmbH Amtsgericht -Registergericht- Memmingen HRB 13605<br />
                        Registergericht: HIS Print &amp; Service GmbH&nbsp; &amp; Co. KG Amtsgericht -Registergericht- Memmingen HRA 11716<br />
                        Gesch&auml;ftsf&uuml;hrer beider Gesellschaften Willi Hoffmann
                    </p>
                    <p>
                        Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27a Umsatzsteuergesetz: DE 263139668<br />
                        <br />
                        <b>Verantwortlich gem&auml;&szlig; &sect; 55 RStV:</b><br />
                        Willi Hoffmann<br />
                        Zur Sch&ouml;nhalde 1<br />
                        89352 Ellzee
                    </p>
                    <p>
                        Haftungshinweis: Trotz sorgf&auml;ltiger inhaltlicher Kontrolle &uuml;bernehmen wir keine Haftung f&uuml;r die Inhalte externer Links. F&uuml;r den Inhalt der verlinkten Seiten sind ausschlie&szlig;lich deren Betreiber verantwortlich.
                    </p>
                </div>
            </Section>
        </Layout>
    )
};

export default Impressum;
